import React, { ReactElement } from "react";

export default function Footer(): ReactElement {
    return (
        <footer className="glass-box bottom-bar white-text">
            <p className="copyright">
                &#169; {new Date().getFullYear()} Merlijn Sevenhuijsen
            </p>
            <ul>
                <li>
                    <a
                        className="flex-around"
                        href="https://www.facebook.com/merlijn.sevenhuijsen/"
                    >
                        <img
                            className="footer-links"
                            src={
                                require("../Images/Links/facebook.png") as string
                            }
                            alt="Facebook link"
                        />
                    </a>
                </li>
                <li>
                    <a
                        className="flex-around"
                        href="https://twitter.com/merlijndussen/"
                    >
                        <img
                            className="footer-links"
                            src={
                                require("../Images/Links/twitter.png") as string
                            }
                            alt="Twitter link"
                        />
                    </a>
                </li>
                <li>
                    <a
                        className="flex-around"
                        href="https://www.instagram.com/merlijndussen/"
                    >
                        <img
                            className="footer-links"
                            src={
                                require("../Images/Links/instagram.png") as string
                            }
                            alt="Instagram link"
                        />
                    </a>
                </li>
                <li>
                    <a
                        className="flex-around gitH"
                        href="https://github.com/SevenhuijsenM"
                    >
                        <img
                            className="footer-links"
                            src={
                                require("../Images/Links/github.png") as string
                            }
                            alt="Github link"
                        />
                    </a>
                </li>
            </ul>
        </footer>
    );
}
