import React, { Component, ReactNode } from "react";
import Modal from "react-modal";
import AuthenticationPage from "../Pages/Authentication/authenticationPage";

interface IProps {
    loginContainerVisible: boolean;
    setLoginVisibility: (value: boolean) => void;
}

interface IState {
    registerPageActive: boolean;
}

export default class LoginModal extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        // Add the modal to the root
        Modal.setAppElement("#root");
    }

    render(): ReactNode {
        const { loginContainerVisible, setLoginVisibility } = this.props;

        return (
            // This works as a modal working on the context
            <Modal
                isOpen={loginContainerVisible}
                onRequestClose={() => setLoginVisibility(false)}
                contentLabel="Login Container"
                className="login-modal"
                overlayClassName="login-modal-background"
                appElement={document.getElementById("#root")}
            >
                <AuthenticationPage modal={true} />
            </Modal>
        );
    }
}
