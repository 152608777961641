import {
    CLEAR,
    SET_ERROR,
    SET_MESSAGE,
} from "../../constants/auth/authenticationConstants";
import { AuthMessageAction } from "../../interfaces/auth/authInterfaces";

export const userAuthMessageActions = {
    setMessage,
    setError,
    clearMessageAndErrors,
};

function setMessage(message: string): AuthMessageAction {
    return {
        type: SET_MESSAGE,
        payload: { message },
    };
}

function clearMessageAndErrors(): AuthMessageAction {
    return { type: CLEAR };
}

function setError(error: string): AuthMessageAction {
    return {
        type: SET_ERROR,
        payload: { error },
    };
}
