import React, {
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { Config } from "../../../App/globalVariables";
import { userAuthMessageActions } from "../../../Reducer/actions/auth/authMessage";
import { LOGOUT } from "../../../Reducer/constants/auth/authenticationConstants";
import { handleResponseRefreshToken } from "../../../Reducer/services/handleResponse";
import { authHeaderRefresh } from "../../authenticationHeader";
import { ResponseDeleteAccountAPI } from "./preferencesInterfaces";

export default function DeleteAccount(): ReactElement {
    const [confirmDeleteAccountOpen, setConfirmDeleteAccountOpen] = useState(
        false
    );
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [loggedOut, setLoggedOut] = useState(false);

    const dispatch = useDispatch();

    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        // Prevent memory leaks by setting the state to empty
        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <div className="form-container glass-box">
            {" "}
            {confirmDeleteAccountOpen ? (
                <div className="reset-password-full-width">
                    <h4>Are you sure, this action cannot be reversed.</h4>
                    <div className="preferences-yes-no">
                        <button
                            className="general-button login-form-button"
                            onClick={handleSubmit}
                        >
                            yes
                        </button>
                        <button
                            className="general-button login-form-button"
                            onClick={() => setConfirmDeleteAccountOpen(false)}
                        >
                            no
                        </button>
                    </div>
                </div>
            ) : (
                <button
                    className="general-button login-form-button"
                    onClick={() => setConfirmDeleteAccountOpen(true)}
                >
                    {" "}
                    Delete Account
                </button>
            )}
            {error ? (
                <div className="glass-box declined contact-form-submission-text">
                    {error}
                </div>
            ) : null}
            {loading ? (
                <div className="glass-box loading contact-form-submission-text">
                    {"Resetting password in..."}
                </div>
            ) : null}
            {loggedOut ? <Redirect to="/login" /> : null}
        </div>
    );

    // function for handling the submit button
    function handleSubmit(e: FormEvent) {
        e.preventDefault();

        setLoading(true);
        if (!loading) {
            const header = {
                ...{ "Content-Type": "application/json" },
                ...authHeaderRefresh(),
            };

            const requestOptions = {
                method: "DELETE",
                headers: header,
            };

            void fetch(`${Config.apiUrl}/auth/delete`, requestOptions)
                .then((response) => {
                    return handleResponseRefreshToken<ResponseDeleteAccountAPI>(
                        response
                    );
                })
                .then((response: ResponseDeleteAccountAPI | null) => {
                    if (response == null) {
                        setError(
                            "You have sent too many requests, please wait for a while."
                        );
                    } else {
                        if (response.message) {
                            localStorage.removeItem("user");
                            localStorage.removeItem("refreshToken");
                            localStorage.removeItem("accessToken");
                            userAuthMessageActions.clearMessageAndErrors();
                            if (isMounted.current) {
                                setLoggedOut(true);
                            }
                        } else if (response.error) {
                            dispatch({
                                type: LOGOUT,
                            });

                            if (isMounted.current) {
                                setError(response.error);
                            }
                        }
                    }
                    return response;
                });
        }
        if (isMounted.current) {
            setLoading(false);
        }
    }
}
