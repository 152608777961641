import React, { useRef, useEffect, ReactElement, ReactNode } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(
    ref: React.MutableRefObject<HTMLDivElement>,
    onClick: (Node) => void
) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                onClick(event.target as Node);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onClick]);
}

/**
 * Component that alerts if you click outside of it
 */
interface IProps {
    children: ReactNode;
    onClick: (Node) => void;
}

export default function OutsideAlerter({
    children,
    onClick,
}: IProps): ReactElement {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, onClick);

    return (
        <div className="outside-alerter" ref={wrapperRef}>
            {children}
        </div>
    );
}
