import React, { ReactElement } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

export type ProtectedRouteProps = {
    isAuthenticated: boolean;
    authenticationPath: string;
} & RouteProps;

export default function ProtectedRoute({
    isAuthenticated,
    authenticationPath,
    ...routeProps
}: ProtectedRouteProps): ReactElement {
    if (isAuthenticated) {
        return <Route {...routeProps} />;
    } else {
        return <Redirect to={{ pathname: authenticationPath }} />;
    }
}
