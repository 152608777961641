import { Dispatch } from "react";
import {
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGOUT,
    REGISTER_FAILURE,
    REGISTER_SUCCESS,
    SET_ERROR,
    SET_MESSAGE,
} from "../../constants/auth/authenticationConstants";
import {
    AuthAction,
    AuthenticationResponseAPI,
    AuthMessageAction,
} from "../../interfaces/auth/authInterfaces";
import { userAuthActions } from "../../services/auth/authenticationServices";

export function loginUser(
    email: string,
    password: string
): (dispatch: Dispatch<AuthAction | AuthMessageAction>) => void {
    // Dispatch a login
    return (dispatch: Dispatch<AuthAction | AuthMessageAction>) => {
        return userAuthActions.login({ email, password }).then(
            // If an user is returned then dispatch a successful login event
            (response: AuthenticationResponseAPI | null) => {
                if (response == null) {
                    dispatch({
                        type: SET_ERROR,
                        payload: { error: response.error },
                    });
                } else {
                    if (response.message) {
                        dispatch({
                            type: LOGIN_SUCCESS,
                            payload: {
                                user: response.user,
                                accessToken: response.accessToken,
                                refreshToken: response.refreshToken,
                            },
                        });
                        dispatch({
                            type: SET_MESSAGE,
                            payload: { message: response.message },
                        });
                    } else if (response.error) {
                        dispatch({
                            type: LOGIN_FAILURE,
                        });

                        dispatch({
                            type: SET_ERROR,
                            payload: { error: response.error },
                        });
                    }
                }
            }
        );
    };
}

function registerUser(
    fullName: string,
    email: string,
    password: string
): (dispatch: Dispatch<AuthAction | AuthMessageAction>) => void {
    return (dispatch: Dispatch<AuthAction | AuthMessageAction>) => {
        return userAuthActions.register({ fullName, email, password }).then(
            // If an user is returned then dispatch a successful login event
            (response: AuthenticationResponseAPI) => {
                if (response.message) {
                    dispatch({
                        type: REGISTER_SUCCESS,
                        payload: {
                            user: response.user,
                            accessToken: response.accessToken,
                            refreshToken: response.refreshToken,
                        },
                    });
                    dispatch({
                        type: SET_MESSAGE,
                        payload: { message: response.message },
                    });
                } else if (response.error) {
                    dispatch({
                        type: REGISTER_FAILURE,
                    });

                    dispatch({
                        type: SET_ERROR,
                        payload: { error: response.error },
                    });
                }
            }
        );
    };
}

function logoutUser(): (dispatch: Dispatch<AuthAction>) => void {
    return (dispatch: Dispatch<AuthAction>) => {
        return userAuthActions.logout().then(() => {
            dispatch({
                type: LOGOUT,
            });
        });
    };
}

export const userActions = {
    loginUser,
    registerUser,
    logoutUser,
};
