import React, { Component, FormEvent, ReactNode } from "react";
import { Redirect } from "react-router-dom";
import { authHeaderForgotPassword } from "./authenticationHeader";
import HidePassword from "./hidePassword";
import {
    ResponseForgotPasswordAPI,
    ResponseForgotPasswordPageAPI,
} from "./PrivatePaths/Preferences/preferencesInterfaces";
import { PasswordStrengthInterface } from "./Register/inputPasswordBox";
import PasswordRegister, {
    calculatePasswordStrength,
} from "./Register/passwordRegister";
import { Config } from "../App/globalVariables";
import { handleResponse } from "../Reducer/services/handleResponse";

interface IState {
    password: string;
    confirmPassword: string;
    error: string;
    loading: boolean;
    loadingToken: boolean;
    validToken: boolean;
    token: string;
    passwordStrength: PasswordStrengthInterface;
    redirect: string;
}

export default class ChangePasswordToken extends Component<null, IState> {
    // Keep track of if the component is mounted to unsubscribe after unmounting ( redirecting )
    _isMounted = false;

    constructor(props: null) {
        super(props);

        let forgotPasswordToken: string;

        if (location.pathname.includes("token=")) {
            forgotPasswordToken = location.pathname.split("token=").pop();
        } else {
            forgotPasswordToken = "";
        }

        const initialPasswordStrength: PasswordStrengthInterface = {
            capitalLetters: 0,
            lowerLetters: 0,
            numbers: 0,
            specialCharacters: 0,
            charactersLength: 0,
            noWhiteSpace: 1,
            strength: 0,
        };

        this.state = {
            password: "",
            confirmPassword: "",
            error: "",
            loadingToken: true,
            loading: false,
            validToken: false,
            token: forgotPasswordToken,
            passwordStrength: initialPasswordStrength,
            redirect: "",
        };
    }

    componentDidMount(): void {
        this._isMounted = true;

        const { token } = this.state;

        if (token != "") {
            const header = {
                ...{ "Content-Type": "application/json" },
                ...authHeaderForgotPassword(token),
            };

            const requestOptions = {
                method: "GET",
                headers: header,
            };

            void fetch(
                `${Config.apiUrl}/auth/verify_forgot_password_token`,
                requestOptions
            )
                .then((response) => {
                    return handleResponse<ResponseForgotPasswordPageAPI>(
                        response
                    );
                })
                .then((response: ResponseForgotPasswordPageAPI | null) => {
                    if (response != null) {
                        if (this._isMounted) {
                            this.setState({
                                validToken: response.validToken,
                                loadingToken: false,
                            });
                        }
                    } else {
                        this.setState({
                            validToken: false,
                            loadingToken: false,
                        });
                    }
                });
        }
    }

    componentWillUnmount(): void {
        this._isMounted = false;
    }

    // function for handling the submit button
    handleSubmit = (e: FormEvent): void => {
        const { loading, password, token } = this.state;
        e.preventDefault();

        this.setState({ loading: true });
        if (this.validateForm() && !loading) {
            const header = {
                ...{ "Content-Type": "application/json" },
                ...authHeaderForgotPassword(token),
            };

            const requestOptions = {
                method: "POST",
                headers: header,
                body: JSON.stringify({
                    password: password,
                }),
            };

            void fetch(
                `${Config.apiUrl}/auth/change_forgotten_password`,
                requestOptions
            )
                .then((response) => {
                    return handleResponse<ResponseForgotPasswordAPI>(response);
                })
                .then((response: ResponseForgotPasswordAPI | null) => {
                    if (response != null) {
                        if (response.message && this._isMounted) {
                            this.setState({ redirect: "/login" });
                        } else if (response.error && this._isMounted) {
                            this.setState({ error: response.error });
                        }
                    } else {
                        this.setState({
                            error:
                                "You have sent too many requests to the server.",
                        });
                    }
                });
        }
        this.setState({ loading: false });
    };

    // Function for changing the password and password strength
    handleNewPassword = (s: string): void => {
        this.setState({
            passwordStrength: calculatePasswordStrength(s),
            password: s,
        });
    };

    // Validate the input registers form and give errors
    validateForm = (): boolean => {
        const { password, confirmPassword, passwordStrength } = this.state;
        if (password != confirmPassword) {
            this.setState({ error: "The passwords do not match." });
            return false;
        } else if (passwordStrength.noWhiteSpace == 0) {
            this.setState({ error: "The password contains a white space." });
            return false;
        } else if (passwordStrength.strength <= 3) {
            this.setState({ error: "The password is not strong enough." });
            return false;
        } else {
            return true;
        }
    };

    render(): ReactNode {
        return (
            <div className="center-container list-lines container">
                {this.renderForgotPassword()}
            </div>
        );
    }

    renderForgotPassword = (): ReactNode => {
        const {
            password,
            error,
            loading,
            loadingToken,
            passwordStrength,
            validToken,
            redirect,
        } = this.state;
        if (redirect) {
            return <Redirect to={this.state.redirect} />;
        } else if (loadingToken) {
            return "Loading token...";
        } else if (!validToken) {
            return "The token was not found...";
        } else {
            return (
                <form
                    className="form-container glass-box"
                    method="post"
                    onSubmit={this.handleSubmit}
                >
                    <h1>Get a new password</h1>
                    <h2> Password</h2>

                    <PasswordRegister
                        className="reset-password-full-width"
                        setPassword={this.handleNewPassword}
                        password={password}
                        passwordStrength={passwordStrength}
                        extraClass={"glass-box"}
                    />

                    <h2>Confirm Password</h2>
                    <HidePassword
                        className="glass-box"
                        placeholder="Confirm password"
                        name="confirm-password"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            this.setState({
                                confirmPassword: e.target.value,
                            })
                        }
                    />
                    <div className="glass-box contact-form-submit-div">
                        <button className="general-button">Set password</button>
                    </div>
                    {error ? (
                        <div className="glass-box declined contact-form-submission-text">
                            {error}
                        </div>
                    ) : null}
                    {loading ? (
                        <div className="glass-box loading contact-form-submission-text">
                            {"Resetting password..."}
                        </div>
                    ) : null}
                </form>
            );
        }
    };
}
