import React, { ReactElement } from "react";
import ChangePassword from "./changePassword";
import DeleteAccount from "../../Authentication/PrivatePaths/Preferences/DeleteAccount";

export default function Preferences(): ReactElement {
    return (
        <div className="container preferences-container" id="Preferences">
            <h1>Preferences</h1>
            <hr className="preferences-separator-line" />
            <h2>Change Password</h2>
            <ChangePassword />
            <hr className="preferences-separator-line" />

            <h2>Delete Account</h2>
            <DeleteAccount />
        </div>
    );
}
