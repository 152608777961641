import React, { ReactElement } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import OutsideAlerter from "../outsideAlerter";
import { userActions } from "../Reducer/actions/auth/loginActions";

interface IProps {
    disableMenu: (Node) => void;
}
export default function UserNavigationList({
    disableMenu,
}: IProps): ReactElement {
    const dispatch = useDispatch();

    return (
        <OutsideAlerter onClick={(node: Node) => disableMenu(node)}>
            <ul className="user-navigation">
                <li>
                    <Link
                        className="fill-container white-text transparent-button"
                        to={"/dashboard"}
                    >
                        Dashboard
                    </Link>
                </li>
                <li>
                    <Link
                        className="fill-container white-text transparent-button"
                        to={"/Preferences"}
                    >
                        Preferences
                    </Link>
                </li>
                <li>
                    <button
                        className="fill-container white-text transparent-button"
                        onClick={() => {
                            dispatch(userActions.logoutUser());
                        }}
                    >
                        Logout
                    </button>
                </li>
            </ul>
        </OutsideAlerter>
    );
}
