import React, { ReactElement } from "react";

interface IProps {
    componentIsStrong: number | boolean;
    normalText: string;
    boldText: string;
}

export default function RenderListComponentStrength({
    componentIsStrong,
    normalText,
    boldText,
}: IProps): ReactElement {
    if (componentIsStrong == 1) {
        return (
            <li
                className="password-list-requirement"
                style={{ color: "green" }}
            >
                <span role="img" aria-labelledby="requirement strong">
                    ✅
                </span>{" "}
                {normalText} <strong> {boldText} </strong>
            </li>
        );
    } else {
        return (
            <li className="password-list-requirement" style={{ color: "red" }}>
                <span
                    role="img"
                    aria-labelledby="requirement not strong enough"
                >
                    ❌
                </span>{" "}
                {normalText} <strong> {boldText} </strong>
            </li>
        );
    }
}
