import React, { ReactElement } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { userActions } from "../../Reducer/actions/auth/loginActions";
import { WebsiteUser } from "../userInterface";

interface IProps {
    user: WebsiteUser;
}

function DashboardInfo({ user }: IProps): ReactElement {
    const dispatch = useDispatch();

    return (
        <div className="center-container">
            {user ? (
                <div className="list-lines glass-box">
                    <h2>Hi {user.name},</h2>
                    <h3>
                        You&apos;re currently logged in with user id: {user.id}.
                    </h3>
                    <h3>
                        The last time you have logged in was:{" "}
                        {parseISOString(user.time_last_login)}.
                    </h3>
                    <h3>
                        The time at which you have created your account was:{" "}
                        {parseISOString(user.time_user_created)}.
                    </h3>
                    <h3>Your role is: {user.role}.</h3>
                    <h3>Your email address is: {user.email}.</h3>
                    <button
                        className="general-button"
                        onClick={() => {
                            dispatch(userActions.logoutUser());
                        }}
                    >
                        Log out
                    </button>
                </div>
            ) : (
                <Redirect to="/login" />
            )}
        </div>
    );
}

export default DashboardInfo;

// Parse from iso string to date object.
function parseISOString(isoString: string): string {
    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const d = new Date(isoString);
    return `${
        monthNames[d.getMonth()]
    } ${d.getDate()}, ${d.getFullYear()} at ${d.getHours()}:${d.getMinutes()}`;
}
