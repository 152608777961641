import React, { FunctionComponent } from "react";
import { Link, useLocation } from "react-router-dom";

interface IProps {
    associatedPage: string;
    pageName: string;
    pageColor: string;
    burgerActive: boolean;
}

const NavigationLink: FunctionComponent<IProps> = ({
    associatedPage,
    pageName,
    pageColor,
    burgerActive,
}) => {
    const location = useLocation();

    return (
        <li
            style={{
                // The color of the page, if the page is selected then add color
                // Otherwise invisible
                background:
                    location.pathname.slice(1) == associatedPage
                        ? pageColor
                        : "transparent",
            }}
            className="navigation-container"
        >
            <Link
                className={
                    burgerActive
                        ? "fill-container white-text transparent-button nav-link-active"
                        : "fill-container white-text transparent-button"
                }
                to={`/${associatedPage}`}
            >
                {pageName}
            </Link>
        </li>
    );
};

export default NavigationLink;
