import React, { ReactElement, ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../Reducer/interfaces";
import { AuthState } from "../Reducer/interfaces/auth/authInterfaces";
import { Project } from "./project";

function getRandomColor() {
    // The characters for a random color
    const letters = "0123456789ABCDEF";
    let color = "#";

    // Add random characters to the color
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

interface IProps {
    project: Project;
    setLoginVisibility: (value: boolean) => void;
}

function SingleProject({ project, setLoginVisibility }: IProps): ReactElement {
    const auth: AuthState = useSelector((state: RootState) => state.auth);

    function getButton(allowed: boolean): ReactNode {
        if (auth.loggedIn || allowed) {
            return (
                <a className="general-button" href={project.link}>
                    {project.linkDescription}
                </a>
            );
        } else {
            return (
                <button
                    className="general-button button-blocked"
                    onClick={() => {
                        setLoginVisibility(true);
                    }}
                >
                    {project.linkDescription}
                </button>
            );
        }
    }
    // Define the color of the project background
    const LinearGradient = `linear-gradient(315deg, ${getRandomColor()}, ${getRandomColor()})`;
    return (
        <div className="project-box background-glass-box content-card">
            <span
                style={{
                    background: LinearGradient,
                }}
                className="background-glass-box-child"
            />
            <span
                style={{
                    background: LinearGradient,
                }}
                className="background-glass-box-child"
            ></span>
            <div className="glass-box">
                <h2>{project.name}</h2>
                <h4>{project.description}</h4>
                {project.image != null ? (
                    <img
                        className="project-image"
                        alt={project.image.name}
                        src={project.image.url}
                    ></img>
                ) : null}
                {project.link ? getButton(project.allowed) : null}
            </div>
        </div>
    );
}

export default SingleProject;
