import React, { ReactElement } from "react";
import { useLocation } from "react-router-dom";

interface IProps {
    burgerActive: boolean;
}

export default function PageBar({ burgerActive }: IProps): ReactElement {
    const location = useLocation();
    return (
        <div
            className={burgerActive ? "page-bar nav-active-bar" : "page-bar"}
            style={{ background: getPagebarColor(location.pathname.slice(1)) }}
        ></div>
    );

    function getPagebarColor(currentPage) {
        switch (currentPage) {
            case "":
                return "#193225";
            case "Projects":
                return "#ea8aba";
            case "About":
                return "#3f48cc";
            case "Contact":
                return "#e26f2e";
            default:
                return "#e07f7f";
        }
    }
}
