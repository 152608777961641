import React, { ReactElement } from "react";

interface IProps {
    percentage: number;
    language: string;
    color: string;
}

export default function LanguageCard({
    percentage,
    language,
    color,
}: IProps): ReactElement {
    // A value that creates a percentage from the percentage prop
    const percentageValue = `${(percentage / 100) * 80}%`;
    return (
        <div className="language-card">
            <div style={{ background: color }} className="language-card-lang">
                {language}{" "}
            </div>
            <div
                className="language-card-value"
                style={{ width: percentageValue }}
            >
                {percentage}%
            </div>
        </div>
    );
}
