import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { WebsiteUser } from "../../Authentication/userInterface";
import DashboardInfo from "../../Authentication/PrivatePaths/dashboardInfo";
import getUser from "../../Authentication/PrivatePaths/getUser";

function Dashboard(): ReactElement {
    const [user, setUser] = useState(null as WebsiteUser);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        let isMounted = true;

        const fetchUser = () => {
            void getUser().then((res) => {
                console.log(res);
                if (res == null && isMounted) {
                    setError(
                        "You have sent too many requests, please wait for a while."
                    );
                } else if (isMounted) {
                    const userDetails = res;
                    setUser(userDetails.user);
                }
                setIsLoading(false);
            });
        };

        void fetchUser();
        // Prevent memory leaks by setting the state to empty
        return () => {
            isMounted = false;
        };
    }, []);

    return <div className="container">{renderLoading()}</div>;

    function renderLoading(): ReactNode {
        if (isLoading) {
            return <div>Loading...</div>;
        } else if (error) {
            return (
                <div className="glass-box declined contact-form-submission-text">
                    {error}
                </div>
            );
        } else {
            return <DashboardInfo user={user} />;
        }
    }
}

export default Dashboard;
