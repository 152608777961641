import React, { ReactElement, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { RootState } from "../../Reducer/interfaces";
import { AuthState } from "../../Reducer/interfaces/auth/authInterfaces";
import ForgotPassword from "./forgotPassword";
import LoginScreen from "../../Authentication/Login/loginScreen";
import RegisterScreen from "./RegisterScreen";

interface IProps {
    modal: boolean;
}

const AuthenticationPage = ({ modal = false }: IProps): ReactElement => {
    const [registerPageActive, setRegisterPageActive] = useState(false);
    const [forgotPasswordPageActive, setForgotPasswordPageActive] = useState(
        false
    );

    const auth: AuthState = useSelector((state: RootState) => state.auth);

    if (auth.loggedIn) {
        return <Redirect to="/dashboard" />;
    } else {
        return (
            <div
                className={
                    modal
                        ? "login-modal-container"
                        : "login-container container"
                }
            >
                {RenderAuthenticationPage()}
            </div>
        );
    }

    function RenderAuthenticationPage() {
        if (registerPageActive) {
            return (
                <RegisterScreen setRegisterPageActive={setRegisterPageActive} />
            );
        } else if (forgotPasswordPageActive) {
            return (
                <ForgotPassword
                    setForgotPasswordPageActive={setForgotPasswordPageActive}
                />
            );
        } else {
            return (
                <LoginScreen
                    setRegisterPageActive={setRegisterPageActive}
                    setForgotPasswordPageActive={setForgotPasswordPageActive}
                />
            );
        }
    }
};

export default AuthenticationPage;
