import React, { useState, ReactElement, lazy, Suspense } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Footer from "../Navigation/footer";
import PageBar from "../Navigation/pageBar";
import Home from "../Pages/home";
import About from "../Pages/About/about";
import Projects from "../Projects/createProjects";
import Contact from "../Pages/Contact/contact";
import Preferences from "../Pages/Authentication/preferences";
import NavigationBar from "../Navigation/navigationBar";
import LoginModal from "../Authentication/loginModal";
import Dashboard from "../Pages/Authentication/dashboard";
import PrivateRoute, {
    ProtectedRouteProps,
} from "../PrivateRoutes/privateRoute";
import { useSelector } from "react-redux";
import { AuthState } from "../Reducer/interfaces/auth/authInterfaces";
import { RootState } from "../Reducer/interfaces";
import LedControl from "../PrivateRoutes/LedProject/ledControl";
import AuthenticationPage from "../Pages/Authentication/authenticationPage";
import ChangePasswordToken from "../Authentication/ForgotPasswordToken";

// Lazy load for the lingo page
const Lingo = lazy(() => import("../Games/Lingo/Lingo"));

// Lazy load for the Rocket Game
const RocketGame = lazy(() => import("../Games/RocketGame/rocketGame"));

const App = (): ReactElement => {
    // Create a context for the visibility of the login modal
    const [loginContainerVisible, setLoginVisibility] = useState(false);
    const [burgerActive, setBurgerActive] = useState(false);
    const auth: AuthState = useSelector((state: RootState) => state.auth);

    const defaultProtectedRouteProps: ProtectedRouteProps = {
        isAuthenticated: auth.loggedIn,
        authenticationPath: "/login",
    };
    return (
        <Router>
            <div className="react-root">
                <NavigationBar
                    setLoginVisibility={setLoginVisibility}
                    burgerActive={burgerActive}
                    setBurgerActive={setBurgerActive}
                />
                <PageBar burgerActive={burgerActive} />
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/about" component={About} />
                    <Route
                        path="/projects"
                        render={() => (
                            <Projects setLoginVisibility={setLoginVisibility} />
                        )}
                    />
                    <Route path="/contact" component={Contact} />
                    <Route path="/login" component={AuthenticationPage} />
                    <Route
                        path="/account/changePassword"
                        component={ChangePasswordToken}
                    />

                    <PrivateRoute
                        {...defaultProtectedRouteProps}
                        path="/preferences"
                        component={Preferences}
                    />
                    <PrivateRoute
                        {...defaultProtectedRouteProps}
                        path="/dashboard"
                        component={Dashboard}
                    />
                    <PrivateRoute
                        {...defaultProtectedRouteProps}
                        path="/ledcontrol"
                        component={LedControl}
                    />
                    <Suspense
                        fallback={
                            <div className="center-container container">
                                loading page...
                            </div>
                        }
                    >
                        <Route
                            path="/rocketgame"
                            render={() => (
                                <RocketGame
                                    setLoginVisibility={setLoginVisibility}
                                />
                            )}
                        />
                        <Route path="/lingo" exact component={Lingo} />
                    </Suspense>
                    <Route
                        component={() => (
                            <div className="center-container list-lines container">
                                {" "}
                                <h1>Page Not Found </h1>{" "}
                            </div>
                        )}
                    />
                </Switch>
                <LoginModal
                    loginContainerVisible={
                        loginContainerVisible && !auth.loggedIn
                    }
                    setLoginVisibility={setLoginVisibility}
                />
            </div>
            <Footer />
        </Router>
    );
};

export default App;
