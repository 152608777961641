import React, { ReactElement } from "react";
import { PasswordStrengthInterface } from "./inputPasswordBox";
import RenderListComponentStrength from "./listComponentStrength";

interface IProps {
    passwordStrength: PasswordStrengthInterface;
}

export default function PasswordStrength({
    passwordStrength,
}: IProps): ReactElement {
    return (
        <div className="password-strength-hint">
            <i className="up-arrow"></i>
            <h4>Requirements for the password:</h4>
            <ul>
                <RenderListComponentStrength
                    componentIsStrong={passwordStrength.charactersLength}
                    normalText="8-20"
                    boldText="Characters"
                />
                <RenderListComponentStrength
                    componentIsStrong={passwordStrength.capitalLetters}
                    normalText="At least"
                    boldText="one capital letter"
                />
                <RenderListComponentStrength
                    componentIsStrong={passwordStrength.lowerLetters}
                    normalText="At least"
                    boldText="one lower case letter"
                />
                <RenderListComponentStrength
                    componentIsStrong={passwordStrength.numbers}
                    normalText="At least"
                    boldText="one number"
                />
                <RenderListComponentStrength
                    componentIsStrong={passwordStrength.specialCharacters}
                    normalText="At least"
                    boldText="one special character"
                />
                <RenderListComponentStrength
                    componentIsStrong={passwordStrength.noWhiteSpace}
                    normalText=""
                    boldText="No whitespaces"
                />
            </ul>
        </div>
    );
}
