import React, { ReactElement, useState } from "react";
import InputPasswordBox, {
    PasswordStrengthInterface,
    PasswordStrengthType,
    ValuePasswordStrengthType,
} from "./inputPasswordBox";
import PasswordStrengthBox from "./passwordStrengthBox";

interface IProps {
    password: string;
    setPassword: (value: string) => void;
    passwordStrength: PasswordStrengthInterface;
    extraClass?: string;
    className?: string;
}

export default function PasswordRegister({
    password,
    setPassword,
    passwordStrength,
    extraClass = "",
    className = "",
}: IProps): ReactElement {
    // A state to see if the input is focussed or not
    const [focused, setFocused] = useState(false);
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    return (
        <div className={`password-strength-hint-box ${className}`}>
            <InputPasswordBox
                onFocus={onFocus}
                onBlur={onBlur}
                focused={focused}
                password={password}
                setPassword={setPassword}
                passwordStrength={passwordStrength.strength}
                extraClass={extraClass}
            />
            {password && focused ? (
                <PasswordStrengthBox
                    passwordStrength={passwordStrength}
                ></PasswordStrengthBox>
            ) : null}
        </div>
    );
}

// Calculate the password strength
export function calculatePasswordStrength(
    password: string
): PasswordStrengthInterface {
    // Requirements for the password length
    const MINIMUM_CHARACTER_LENGTH = 8;
    const MAXIMUM_CHARACTER_LENGTH = 20;

    // The password contains a special character
    const specialCharacterRegularExpression = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
    const specialCharacters = (specialCharacterRegularExpression.test(password)
        ? 1
        : 0) as ValuePasswordStrengthType;

    // The password contains a capital letter
    const capitalLetters = (new RegExp("[A-Z]").test(password)
        ? 1
        : 0) as ValuePasswordStrengthType;

    // The password contains a lower case letter
    const lowerLetters = (new RegExp("[a-z]").test(password)
        ? 1
        : 0) as ValuePasswordStrengthType;

    // The password contains a number
    const numbers = (new RegExp("[0-9]").test(password)
        ? 1
        : 0) as ValuePasswordStrengthType;

    // The password contains a white space
    const noWhiteSpace = password.indexOf(" ") >= 0 ? 0 : 1;

    // The character length is between 8 and 20 characters
    const charactersLength =
        password.length >= MINIMUM_CHARACTER_LENGTH &&
        password.length <= MAXIMUM_CHARACTER_LENGTH
            ? 1
            : 0;

    // The total strength of the password
    return {
        capitalLetters,
        lowerLetters,
        numbers,
        specialCharacters,
        charactersLength,
        noWhiteSpace,
        strength: (specialCharacters +
            charactersLength +
            capitalLetters +
            lowerLetters +
            numbers) as PasswordStrengthType,
    } as PasswordStrengthInterface;
}
