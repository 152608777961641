// Logging user in  and out
export const LOGIN_SUCCESS = "USERS_LOGIN_SUCCESS";
export const LOGIN_FAILURE = "USERS_LOGIN_FAILURE";
export const REGISTER_SUCCESS = "USERS_REGISTRATION_SUCCESS";
export const REGISTER_FAILURE = "USERS_REGISTRATION_FAILURE";
export const LOGOUT = "USERS_LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR = "CLEAR";
export const SET_ERROR = "SET_ERROR";

export type authActionTypes =
    | typeof LOGIN_SUCCESS
    | typeof LOGIN_FAILURE
    | typeof REGISTER_SUCCESS
    | typeof REGISTER_FAILURE
    | typeof LOGOUT;

export type authMessageTypes =
    | typeof SET_MESSAGE
    | typeof SET_ERROR
    | typeof CLEAR;
