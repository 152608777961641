import React, { ReactElement } from "react";
import { Project } from "./project";
import SingleProject from "./singleProject";

interface IProps {
    setLoginVisibility: (value: boolean) => void;
}
export default function Projects({ setLoginVisibility }: IProps): ReactElement {
    const MyProjects: Project[] = [
        {
            name: "Sensor Fusion",
            description:
                "With three other students we have created a tool to fuse data from multiple sensors. Starting from a robot a script was written that documented data of incremental sensors, which was then fused with data from Vive trackers. This has given me more insights into data analysis, tracking of sensors, precision and probabilities.",
            image: {
                name: "Robot Image",
                url: require("../Images/Projects/robot.png") as string,
            },
        },
        {
            name: "Arm Simulation",
            description:
                "With one other student I have created a tool that is able to simulate real arm movements. Data wfrom a real arm was used to try and imitate it within Maple. Although the results were not as expected, it has given me more insights into kinematics and using modeling software such as Maple to model robots.",
        },
        {
            name: "DADAF",
            description:
                "A tool made for a company to create assistants for existing models. It is a detached tool that works directly integrated with their API. This was done with a team of ten students. Within the project I focused on strenghtening my React skills, and learning more about Docker, Proxys, and backend processes in general.",
        },
        {
            name: "Viseye",
            description:
                "An interactive tool to visualize eye-tracking data." +
                " Made by a group of 6 students within 8 weeks. Skills gained: frontend development knowledge, data management, working in a team.",
            image: {
                name: "Viseye figure",
                url: require("../Images/Projects/viseye-figure.png") as string,
            },
            linkDescription: "Go to project",
            link: "https://viseye.web.app",
            allowed: false,
        },
        {
            name: "Rocket game",
            description:
                "A game resolved around flying a rocket to the end destination. This project was done to learn more about creating games as well as applying the gained programming knowledge",
            linkDescription: "Read more about it",
            link: "https://www.merlijnsevenhuijsen.com/rocketgame",
            allowed: true,
        },
        {
            name: "Wordle",
            description:
                "An wordle remake made in a few days. The goal is to guess a word using previous words and the associated hints. This has given me more insights into required logic, and frontend development.",
            linkDescription: "Try to play it",
            link: "https://www.merlijnsevenhuijsen.com/lingo",
            allowed: true,
        },
        {
            name: "Website",
            description:
                "Create a website with limited experience, include multiple different pages with different effects. Get to know more about the process of creating websites from scratch. The website you are currently looking at is the final result. This has given me more insights into backend development, frontend development, and design.",
        },
        {
            name: "Process Mining",
            description:
                "A tool to analyze the data from a BPI_Challenge, which requires a dataset to train on and uses artificial intelligence to make predictions based on the trained data. Both the next event and time until the next event were predicted, and the tool had an accuracy of 75% on the prediction of the next event. This has given me more insights into data analysis, and machine learning, artificial intelligence and process mining.",
        },
        {
            name: "Google Home Takeover tool",
            description:
                "In order to gain a deeper understanding in topics one has to put effort into learning it. For a project in an introductory course to hacking I have created ( among two others ) tool in order to try and take over a Google Nest Mini. This has given me more insights into hacking, security and python.",
            linkDescription: "Go to the github",
            link: "https://github.com/SevenhuijsenM/GoogleHomeTakeover",
            allowed: false,
        },
    ];

    return (
        <div className="projects container" id="Projects">
            {MyProjects.map(createProject)}
        </div>
    );

    function createProject(project: Project) {
        return (
            <SingleProject
                key={project.name}
                setLoginVisibility={setLoginVisibility}
                project={project}
            />
        );
    }
}
