import React, { ReactElement } from "react";
import { contactFormType } from "./contactFormTypes";

interface IProps {
    name: string;
    errorCode: string;
    value: string;
    valueSetter: (value: string) => void;
    type: contactFormType;
}
export default function ContactInputBox({
    name,
    errorCode,
    value,
    valueSetter,
    type,
}: IProps): ReactElement {
    return (
        <div className="contact-form-padded">
            {name != "message" ? (
                <input
                    style={{
                        border:
                            errorCode &&
                            errorCode.toLowerCase().includes(name.toLowerCase())
                                ? "solid rgba(255, 33, 33, 0.7) 3px"
                                : null,
                    }}
                    name={name}
                    type={type}
                    className="glass-box"
                    placeholder={name}
                    value={value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        valueSetter(e.target.value)
                    }
                />
            ) : (
                <textarea
                    style={{
                        border:
                            errorCode && errorCode.includes("message")
                                ? "solid rgba(255, 33, 33, 0.7) 3px"
                                : null,
                    }}
                    name="message"
                    className="glass-box"
                    placeholder="Type Message Here..."
                    value={value}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                        valueSetter(e.target.value)
                    }
                />
            )}
        </div>
    );
}
