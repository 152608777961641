import React, {
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from "react";
import { Config } from "../../App/globalVariables";
import { handleResponseRefreshToken } from "../../Reducer/services/handleResponse";
import { authHeaderRefresh } from "../../Authentication/authenticationHeader";
import { PasswordStrengthInterface } from "../../Authentication/Register/inputPasswordBox";
import PasswordRegister, {
    calculatePasswordStrength,
} from "../../Authentication/Register/passwordRegister";
import { ResponseChangePasswordAPI } from "../../Authentication/PrivatePaths/Preferences/preferencesInterfaces";
import HidePassword from "../../Authentication/hidePassword";

export default function ChangePassword(): ReactElement {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        // Prevent memory leaks by setting the state to empty
        return () => {
            isMounted.current = false;
        };
    }, []);

    //The strength of the filled in password
    const initialPasswordStrength: PasswordStrengthInterface = {
        capitalLetters: 0,
        lowerLetters: 0,
        numbers: 0,
        specialCharacters: 0,
        charactersLength: 0,
        noWhiteSpace: 1,
        strength: 0,
    };
    const [passwordStrength, setPasswordStrength] = useState(
        initialPasswordStrength
    );

    return (
        <form
            className="form-container glass-box"
            method="post"
            onSubmit={handleSubmit}
        >
            <h3>Old Password</h3>
            <HidePassword
                className="glass-box"
                name="confirm-password"
                placeholder="Old Password"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setOldPassword(e.target.value)
                }
            />
            <h3> New Password</h3>

            <PasswordRegister
                className="reset-password-full-width"
                setPassword={handleNewPassword}
                password={newPassword}
                passwordStrength={passwordStrength}
                extraClass={"glass-box"}
            />

            <h3>Confirm Password</h3>
            <HidePassword
                className="glass-box"
                name="confirm-password"
                placeholder="Confirm Password"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setConfirmPassword(e.target.value)
                }
            />
            <button className="general-button login-form-button">
                Change Password
            </button>
            {error ? (
                <div className="glass-box declined contact-form-submission-text">
                    {error}
                </div>
            ) : null}
            {loading ? (
                <div className="glass-box loading contact-form-submission-text">
                    {"Resetting password..."}
                </div>
            ) : null}
            {message ? (
                <div className="glass-box accepted contact-form-submission-text">
                    {message}
                </div>
            ) : null}
        </form>
    );

    // Function for changing the password and password strength
    function handleNewPassword(s: string) {
        setPasswordStrength(calculatePasswordStrength(s));
        setNewPassword(s);
    }

    // function for handling the submit button
    function handleSubmit(e: FormEvent) {
        e.preventDefault();
        setLoading(true);
        setMessage("");
        setError("");
        if (validateForm() && !loading) {
            const header = {
                ...{ "Content-Type": "application/json" },
                ...authHeaderRefresh(),
            };

            const requestOptions = {
                method: "POST",
                headers: header,
                body: JSON.stringify({
                    oldPassword,
                    newPassword,
                }),
            };

            void fetch(`${Config.apiUrl}/auth/change_password`, requestOptions)
                .then((response) => {
                    return handleResponseRefreshToken<ResponseChangePasswordAPI>(
                        response
                    );
                })
                .then((response: ResponseChangePasswordAPI) => {
                    if (response.message && isMounted.current) {
                        setMessage(response.message);
                    } else if (response.error && isMounted.current) {
                        setError(response.error);
                    }
                });
        }

        if (isMounted.current) {
            setLoading(false);
        }
    }

    // Validate the input registers form and give errors
    function validateForm(): boolean {
        if (newPassword != confirmPassword) {
            setError("The passwords do not match.");
            return false;
        } else if (passwordStrength.noWhiteSpace == 0) {
            setError("The password contains a white space.");
            return false;
        } else if (passwordStrength.strength <= 3) {
            setError("The password is not strong enough.");
            return false;
        } else {
            return true;
        }
    }
}
