import { authHeaderRefresh } from "../../../Authentication/authenticationHeader";
import { LoginFormInputCredentials } from "../../../Authentication/Login/loginInterfaces";
import { RegisterFormInputCredentials } from "../../../Authentication/Register/RegisterInterfaces";
import { Config } from "../../../App/globalVariables";
import { userAuthMessageActions } from "../../actions/auth/authMessage";
import {
    AuthenticationResponseAPI,
    GenerateAccessTokenResponseAPI,
    LoginCredentialsAPI,
} from "../../interfaces/auth/authInterfaces";
import {
    handleResponse,
    handleResponseRefreshToken,
    handleSuccessfulAuthentication,
} from "../handleResponse";

export const userAuthActions = {
    login,
    logout,
    register,
    generateAccessToken,
};

function login({
    email,
    password,
}: LoginFormInputCredentials): Promise<AuthenticationResponseAPI> {
    const loginCredentials: LoginCredentialsAPI = { email, password };
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(loginCredentials),
    };

    return fetch(`${Config.apiUrl}/auth/login`, requestOptions)
        .then((response) => {
            return handleResponse<AuthenticationResponseAPI>(response);
        })
        .then((response: AuthenticationResponseAPI) => {
            return handleSuccessfulAuthentication(response);
        });
}

function register({
    fullName,
    email,
    password,
}: RegisterFormInputCredentials): Promise<AuthenticationResponseAPI> {
    const registerCredentials: RegisterFormInputCredentials = {
        fullName,
        email,
        password,
    };
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(registerCredentials),
    };

    return fetch(`${Config.apiUrl}/auth/register`, requestOptions)
        .then((response) => {
            return handleResponse<AuthenticationResponseAPI>(response);
        })
        .then((response: AuthenticationResponseAPI | null) => {
            return handleSuccessfulAuthentication(response);
        });
}

function logout(): Promise<void> {
    const header = {
        ...{ "Content-Type": "application/json" },
        ...authHeaderRefresh(),
    };

    const requestOptions = {
        method: "DELETE",
        headers: header,
    };

    return fetch(`${Config.apiUrl}/auth/logout`, requestOptions).then(() => {
        // Remove user from local storage to log user out
        localStorage.removeItem("user");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        userAuthMessageActions.clearMessageAndErrors();
    });
}

function generateAccessToken(): Promise<GenerateAccessTokenResponseAPI> {
    const header = {
        ...{ "Content-Type": "application/json" },
        ...authHeaderRefresh(),
    };

    const requestOptions = {
        method: "GET",
        headers: header,
    };
    return fetch(`${Config.apiUrl}/auth/token/generate`, requestOptions)
        .then((res) => {
            return handleResponseRefreshToken<GenerateAccessTokenResponseAPI>(
                res
            );
        })
        .then((text: GenerateAccessTokenResponseAPI | null) => {
            if (text != null) {
                if (text.accessToken) {
                    localStorage.setItem("accessToken", text.accessToken);
                }
                return text;
            } else {
                return {
                    error:
                        "You have sent too many requests, please try again later.",
                };
            }
        });
}
