import React, { ReactElement } from "react";

export default function Home(): ReactElement {
    return (
        <div className="center-container list-lines container" id="Home">
            <h1 className="white-text">Merlijn Sevenhuijsen</h1>
            <h2 className="white-text">
                A dutch Autonomous Systems student with too many interests.
            </h2>
        </div>
    );
}
