import React, { ReactElement } from "react";
import HidePassword from "../hidePassword";

interface IProps {
    password: string;
    setPassword: (value: string) => void;
    passwordStrength: PasswordStrengthType;
    onFocus: () => void;
    onBlur: () => void;
    focused: boolean;
    extraClass?: string;
}
export type ValuePasswordStrengthType = 0 | 1;
export type PasswordStrengthType = 0 | 1 | 2 | 3 | 4 | 5;

export interface PasswordStrengthInterface {
    capitalLetters: ValuePasswordStrengthType;
    lowerLetters: ValuePasswordStrengthType;
    numbers: ValuePasswordStrengthType;
    specialCharacters: ValuePasswordStrengthType;
    charactersLength: ValuePasswordStrengthType;
    noWhiteSpace: ValuePasswordStrengthType;
    strength: PasswordStrengthType;
}

export default function InputPasswordBox({
    password,
    setPassword,
    passwordStrength,
    onFocus,
    onBlur,
    focused,
    extraClass = "",
}: IProps): ReactElement {
    return (
        <span className="forgot-password-span">
            <HidePassword
                name="register-password"
                onFocus={onFocus}
                onBlur={onBlur}
                className={`${extraClass}`}
                // Only append the style background color if a password is set.
                style={{
                    ...(focused && password
                        ? {
                              backgroundImage: switchCaseBackgroundPassword(
                                  passwordStrength
                              ),
                          }
                        : {}),
                }}
                placeholder="Password"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPassword(e.target.value)
                }
            />
            {focused && password ? (
                <span className="password-strength-text">
                    {switchCasePasswordStrengthText(passwordStrength)}
                </span>
            ) : null}
        </span>
    );
}

function switchCaseBackgroundPassword(
    passwordStrength: PasswordStrengthType
): string {
    // Calculate the width of the strength hint
    const widthStrengthHintPercentage = (passwordStrength / 5) * 100;
    const transparentWidthHint = 100 - widthStrengthHintPercentage;

    switch (passwordStrength) {
        case 0:
        case 1:
            return `linear-gradient(
                to left,
                rgba(0, 0, 0, 0) ${transparentWidthHint}%,
                red ${transparentWidthHint}%
            )`;
        case 2:
            return `linear-gradient(
                to left,
                rgba(0, 0, 0, 0) ${transparentWidthHint}%,
                orange ${transparentWidthHint}%
            )`;
        case 3:
            return `linear-gradient(
                to left,
                rgba(0, 0, 0, 0) ${transparentWidthHint}%,
                yellow ${transparentWidthHint}%
            )`;
        case 4:
        case 5:
            return `linear-gradient(
                to left,
                rgba(0, 0, 0, 0) ${transparentWidthHint}%,
                green ${transparentWidthHint}%
            )`;
    }
}

function switchCasePasswordStrengthText(
    passwordStrength: PasswordStrengthType
): string {
    // Calculate the width of the strength hint
    switch (passwordStrength) {
        case 0:
        case 1:
            return "very weak";
        case 2:
            return "weak";
        case 3:
            return "strong";
        case 4:
        case 5:
            return "very strong";
    }
}
