import React, { Component, FormEvent, ReactNode } from "react";
import ContactInputBox from "./contactInputBox";
import {
    ContactFormInputCredentials,
    ContactFormReponseAPI,
} from "./contactFormTypes";
import { Config } from "../../App/globalVariables";

class Contact extends Component {
    // Define the state of this component
    state = {
        name: "",
        subject: "",
        email: "",
        message: "",
        mailSent: false,
        error: "",
        loading: false,
    };

    handleFormSubmit = (e: FormEvent): void => {
        e.preventDefault();

        // Check if the email, the message, the name and the subject are valid
        if (
            this.validateNameAndSubject() &&
            this.handleEmail() &&
            this.validateMessage()
        ) {
            // Set the state of the error message back to null
            // Also set loading to true
            this.setState({
                error: null,
                loading: true,
                name: "",
                subject: "",
                email: "",
                message: "",
            });

            // The template that will be sent to the backend api
            const emailParameters: ContactFormInputCredentials = {
                name: this.state.name,
                subject: this.state.subject,
                email: this.state.email,
                message: this.state.message,
            };

            void sendEmailAPI(emailParameters).then(
                (response: ContactFormReponseAPI) => {
                    if (response != null) {
                        if (response.message) {
                            this.setState({
                                loading: false,
                                mailSent: true,
                            });
                        } else if (response.error) {
                            this.setState({
                                loading: false,
                                mailSent: false,
                                error: response.error,
                            });
                        } else {
                            this.setState({
                                loading: false,
                                mailSent: false,
                                error:
                                    "Something went wrong with your request, please try again later.",
                            });
                        }
                    } else {
                        this.setState({
                            loading: false,
                            mailSent: false,
                            error:
                                "You have sent too many requests, please try again later.",
                        });
                    }
                }
            );
        } else {
            // Set the state to no email sent
            this.setState({ mailSent: false });
        }
    };

    handleEmail(): boolean {
        // The email length is 0
        if (this.state.email.length == 0) {
            this.setState({ error: "The e-mail address is empty." });
            return false;
        } else if (!validateEmail(this.state.email)) {
            this.setState({ error: "The e-mail address is invalid." });
            return false;
        }

        // Set the form address to valid otherwise
        return true;
    }

    validateMessage(): boolean {
        // Define the minimum amount of characters in the message container
        const requiredCharacters = 30;

        // Define the maximum amount of characters in the message container
        const maximumCharacters = 1500;

        // The message does not contain at least 30 characters
        if (this.state.message.length - requiredCharacters <= 0) {
            this.setState({
                error: `The message must contain at least ${String(
                    requiredCharacters
                )} characters.`,
            });
            return false;
        } else if (
            this.state.message.length - requiredCharacters >
            maximumCharacters
        ) {
            this.setState({
                error: `The message must contain at most
                        ${maximumCharacters}
                     characters.`,
            });
            return false;
        }

        // Otherwise the message is valid.
        return true;
    }

    validateNameAndSubject(): boolean {
        // Define the maximum amount of characters in the message subject and name field
        const maximumCharacters = 150;

        // The name needs to have at least 1 character
        if (this.state.name.length == 0) {
            this.setState({
                error: "The full name must contain at least 1 character.",
            });
            return false;
        } else if (this.state.name.length > maximumCharacters) {
            this.setState({
                error: `The full name cannot contain more than 
                    ${maximumCharacters} 
                     characters.`,
            });
            return false;
        }

        // The subject needs to have at least 1 character
        if (this.state.subject.length == 0) {
            this.setState({
                error: "The subject must contain at least 1 character.",
            });
            return false;
        } else if (this.state.subject.length > maximumCharacters) {
            this.setState({
                error: `The subject cannot contain more than 
                    ${maximumCharacters} 
                     characters.`,
            });
            return false;
        }

        // Otherwise accept the name and subject
        return true;
    }

    render(): ReactNode {
        return (
            <div
                className="container contact-section center-container"
                id="Contact"
            >
                <form
                    action="#"
                    id="contact-form"
                    className="glass-box form-container"
                    method="POST"
                    onSubmit={this.handleFormSubmit}
                >
                    <h1>Contact Me</h1>

                    <ContactInputBox
                        name="Full Name"
                        errorCode={this.state.error}
                        value={this.state.name}
                        valueSetter={(value) => this.setState({ name: value })}
                        type="text"
                    />
                    <ContactInputBox
                        name="Subject"
                        errorCode={this.state.error}
                        value={this.state.subject}
                        valueSetter={(value) =>
                            this.setState({ subject: value })
                        }
                        type="text"
                    />
                    <ContactInputBox
                        name="E-mail"
                        errorCode={this.state.error}
                        value={this.state.email}
                        valueSetter={(value) => this.setState({ email: value })}
                        type="email"
                    />
                    <ContactInputBox
                        name="message"
                        errorCode={this.state.error}
                        value={this.state.message}
                        valueSetter={(value) =>
                            this.setState({ message: value })
                        }
                        type="text"
                    />
                    <div className="glass-box contact-form-submit-div">
                        <button className="general-button" value="Send">
                            Send email
                        </button>
                    </div>
                </form>
                {this.state.mailSent ? (
                    <div className="glass-box accepted contact-form-submission-text">
                        The contact form has been submitted!
                    </div>
                ) : null}
                {this.state.error ? (
                    <div className="glass-box declined contact-form-submission-text">
                        {this.state.error}
                    </div>
                ) : null}
                {this.state.loading ? (
                    <div className="glass-box contact-form-submission-text">
                        Loading...
                    </div>
                ) : null}
            </div>
        );
    }
}

export function validateEmail(email: string): boolean {
    // The regular expression of an email, with 99.99% of the possible emails accepted.
    const regularExpression = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    // Set the form address to valid otherwise
    return regularExpression.test(email);
}

export default Contact;

function sendEmailAPI(
    bodyParameters: ContactFormInputCredentials
): Promise<ContactFormReponseAPI> | null {
    const requestOptions: RequestInit = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bodyParameters),
    };

    return fetch(`${Config.apiUrl}/email/contact`, requestOptions).then(
        (response) => {
            return response
                .json()
                .then((text: Promise<ContactFormReponseAPI> | null) => {
                    return text;
                });
        }
    );
}
