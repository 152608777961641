import React, {
    useState,
    useEffect,
    useRef,
    ReactElement,
    FormEvent,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateEmail } from "../Contact/contact";
import { userAuthMessageActions } from "../../Reducer/actions/auth/authMessage";
import { userActions } from "../../Reducer/actions/auth/loginActions";
import { RootState } from "../../Reducer/interfaces";
import { AuthMessageState } from "../../Reducer/interfaces/auth/authInterfaces";
import HidePassword from "../../Authentication/hidePassword";
import { PasswordStrengthInterface } from "../../Authentication/Register/inputPasswordBox";
import PasswordRegister, {
    calculatePasswordStrength,
} from "../../Authentication/Register/passwordRegister";

interface IProps {
    setRegisterPageActive: (value: boolean) => void;
}

export default function RegisterScreen({
    setRegisterPageActive,
}: IProps): ReactElement {
    // The username, email and password as a usestate
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);

    //The strength of the filled in password
    const initialPasswordStrength: PasswordStrengthInterface = {
        capitalLetters: 0,
        lowerLetters: 0,
        numbers: 0,
        specialCharacters: 0,
        charactersLength: 0,
        noWhiteSpace: 1,
        strength: 0,
    };

    const [passwordStrength, setPasswordStrength] = useState(
        initialPasswordStrength
    );
    const mounted = useRef(true);
    const dispatch = useDispatch();

    // Clear the current errors and messages
    userAuthMessageActions.clearMessageAndErrors();

    // Get the redux store of authentication states
    const authMessage: AuthMessageState = useSelector(
        (state: RootState) => state.authMessage
    );

    // Function that handles the submitting of the register form
    function handleSubmit(e: FormEvent) {
        e.preventDefault();

        setLoading(true);
        if (validateForm() && !loading) {
            dispatch(userActions.registerUser(fullName, email, password));
        }
        setLoading(false);
    }

    // Validate the input registers form and give errors
    function validateForm(): boolean {
        if (!validateEmail(email)) {
            dispatch(
                userAuthMessageActions.setError(
                    "Please fill in a valid email address."
                )
            );
            return false;
        } else if (password != confirmPassword) {
            dispatch(
                userAuthMessageActions.setError("The passwords do not match.")
            );
            return false;
        } else if (passwordStrength.noWhiteSpace == 0) {
            dispatch(
                userAuthMessageActions.setError(
                    "The password contains a white space."
                )
            );
            return false;
        } else if (passwordStrength.strength <= 3) {
            dispatch(
                userAuthMessageActions.setError(
                    "The password is not strong enough."
                )
            );

            return false;
        } else {
            return true;
        }
    }

    // Use effect to keep track of whether the ppage is mounted
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    // Function for changing the password and password strength
    function handleNewPassword(newPassword: string) {
        setPasswordStrength(calculatePasswordStrength(newPassword));
        setPassword(newPassword);
    }

    return (
        <div className="login-box">
            <h1>Register</h1>
            <form
                className="login-form"
                action="index.html"
                method="post"
                onSubmit={handleSubmit}
            >
                <h2>Full Name</h2>
                <input
                    type="fullname"
                    name="register-name"
                    placeholder="Full Name"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFullName(e.target.value)
                    }
                    required
                />
                <h2>E-mail</h2>
                <input
                    type="email"
                    name="register-email"
                    placeholder="Email Address"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setEmail(e.target.value)
                    }
                    required
                />
                <h2>Password</h2>

                <PasswordRegister
                    className="password-register-field "
                    setPassword={handleNewPassword}
                    password={password}
                    passwordStrength={passwordStrength}
                    extraClass="hide-password-box-full"
                />

                <h2>Confirm Password</h2>
                <HidePassword
                    placeholder="Confirm password"
                    name="confirm-password"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setConfirmPassword(e.target.value)
                    }
                />
                <button className="general-button login-form-button">
                    Register
                </button>
            </form>
            {authMessage.error ? (
                <div className="glass-box declined contact-form-submission-text">
                    {authMessage.error}
                </div>
            ) : null}
            {loading ? (
                <div className="glass-box loading contact-form-submission-text">
                    {"Registering in..."}
                </div>
            ) : null}
            {authMessage.message ? (
                <div className="glass-box accepted contact-form-submission-text">
                    {authMessage.message}
                </div>
            ) : null}
            <button
                onClick={() => setRegisterPageActive(false)}
                className="create-account"
            >
                Already have an account? Log in here.
            </button>
        </div>
    );
}
