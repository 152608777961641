import {
    CLEAR,
    SET_ERROR,
    SET_MESSAGE,
} from "../../constants/auth/authenticationConstants";
import {
    AuthMessageAction,
    AuthMessageState,
} from "../../interfaces/auth/authInterfaces";

export function authMessage(
    state: AuthMessageState = {},
    action: AuthMessageAction = {}
): AuthMessageState {
    switch (action.type) {
        case SET_MESSAGE:
            return { message: action.payload.message };
        case SET_ERROR:
            return { error: action.payload.error };
        case CLEAR:
            return { message: "", error: "" };
        default:
            return state;
    }
}
