import { AuthenticationResponseAPI } from "../interfaces/auth/authInterfaces";
import { userAuthActions } from "./auth/authenticationServices";

export function handleResponse<Type>(response: Response): Promise<Type> {
    return response.json().then((text: Promise<Type> | null) => {
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                void userAuthActions.logout();
                location.reload();
            } else if (response.status === 408) {
                void userAuthActions.generateAccessToken();
            } else if (response.status == 429) {
                return null;
            }
        }
        return text;
    });
}

export function handleResponseRefreshToken<Type>(
    response: Response
): Promise<Type> | null {
    return response.json().then((text: Promise<Type>) => {
        if (!response.ok && response.status != 429) {
            void userAuthActions.logout();
        } else if (response.status == 429) {
            return null;
        }
        return text;
    });
}

export function handleSuccessfulAuthentication(
    response: AuthenticationResponseAPI | null
): AuthenticationResponseAPI {
    if (response != null) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        if (response.user) {
            localStorage.setItem("user", JSON.stringify(response.user));
            localStorage.setItem("accessToken", response.accessToken);
            localStorage.setItem("refreshToken", response.refreshToken);
        }

        return response;
    } else {
        return {
            error:
                "You have sent too many requests, please try again in a while.",
        };
    }
}
