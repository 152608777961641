import { Config } from "../../App/globalVariables";
import { handleResponse } from "../../Reducer/services/handleResponse";
import authHeader from "../authenticationHeader";
import { GetUserResponseAPI } from "../userInterface";

export default function getUser(): Promise<GetUserResponseAPI | null> {
    const header = {
        ...{ "Content-Type": "application/json" },
        ...authHeader(),
    };

    const requestOptions: RequestInit = {
        method: "GET",
        headers: header,
    };

    return fetch(`${Config.apiUrl}/auth/user`, requestOptions).then(
        (response) => {
            return handleResponse<GetUserResponseAPI>(response);
        }
    );
}
