import { WebsiteUser } from "../../../Authentication/userInterface";
import {
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGOUT,
    REGISTER_FAILURE,
    REGISTER_SUCCESS,
} from "../../constants/auth/authenticationConstants";
import { AuthAction, AuthState } from "../../interfaces/auth/authInterfaces";

const user: WebsiteUser = JSON.parse(
    localStorage.getItem("user")
) as WebsiteUser;
const refreshToken: string = localStorage.getItem("refreshToken");
const accessToken: string = localStorage.getItem("accessToken");

const initialState: AuthState = (user
    ? {
          loggedIn: true,
          user,
          accessToken: accessToken,
          refreshToken: refreshToken,
      }
    : { loggedIn: false }) as AuthState;

export function auth(
    state: AuthState = initialState,
    action: AuthAction = {}
): AuthState {
    switch (action.type) {
        case LOGIN_SUCCESS:
        case REGISTER_SUCCESS:
            return {
                loggedIn: true,
                user: action.payload.user,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
            };
        case LOGIN_FAILURE:
        case REGISTER_FAILURE:
            return { loggedIn: false };
        case LOGOUT:
            return { loggedIn: false };
        default:
            return state;
    }
}
