import React, { ReactElement } from "react";

interface IProps {
    percentage: number;
    programmingLanguage: string;
    color: string;
}

export default function ProgrammingCard({
    percentage,
    programmingLanguage,
    color,
}: IProps): ReactElement {
    return (
        <div className="programming-card">
            <div className="box-about glass-box">
                <div>
                    <div className="percent-about">
                        <svg>
                            <circle cx="70" cy="70" r="70"></circle>
                            <circle
                                style={{
                                    strokeDashoffset: `calc(440px - (440px * ${percentage}) / 100)`,
                                }}
                                cx="70"
                                cy="70"
                                r="70"
                            ></circle>
                        </svg>
                        <div className="number">
                            <h2>
                                {percentage}
                                <span>%</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ background: color }} className="text">
                {programmingLanguage}
            </div>
        </div>
    );
}
