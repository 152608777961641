import React, { useState, useEffect, ReactElement, useRef } from "react";

interface DadJokeApiResponse {
    joke: string;
}

const Dadjoke = (): ReactElement => {
    const [joke, updateJoke] = useState("");
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
        void fetchJoke();

        // Prevent memory leaks by setting the state to empty
        return () => {
            isMounted.current = false;
        };
    }, []);

    async function fetchJoke() {
        // Update the user that the joke is loading
        updateJoke("Loading...");

        const response = await fetch("https://icanhazdadjoke.com/", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "User-Agent":
                    "Merlijn Sevenhuijsen ( personal website ) at merlijnsevenhuijsen.com (https://github.com/SevenhuijsenM/MerlijnSevenhuijsen) contact at contact@merlijnsevenhuijsen.com",
            },
        });

        // If succesfully a joke has been fetched, then update the textbox
        const json = (await response.json()) as DadJokeApiResponse;

        if (isMounted.current) {
            updateJoke(json.joke);
        }
    }

    return (
        <div className="glass-box dad-joke-container">
            <button
                className="rgb-button dad-joke-button"
                onClick={() => fetchJoke()}
            >
                <span>Generate a new dad joke</span>
            </button>
            <h2 className="glass-box dad-joke-content">{joke}</h2>
            <a
                className="dad-joke-link white-text"
                href="https://icanhazdadjoke.com/"
            >
                {" "}
                Generated by icanhazdadjoke.com/
            </a>
        </div>
    );
};

export default Dadjoke;
