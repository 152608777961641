import React, {
    useState,
    FormEvent,
    ReactElement,
    useRef,
    useEffect,
} from "react";
import { validateEmail } from "../Contact/contact";
import { Config } from "../../App/globalVariables";
import { handleResponse } from "../../Reducer/services/handleResponse";
import { ResponseForgotPasswordAPI } from "../../Authentication/PrivatePaths/Preferences/preferencesInterfaces";

interface IProps {
    setForgotPasswordPageActive: (value: boolean) => void;
}

export default function ForgotPassword({
    setForgotPasswordPageActive,
}: IProps): ReactElement {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        // Prevent memory leaks by setting the state to empty
        return () => {
            isMounted.current = false;
        };
    }, []);

    // function for handling the submit button
    function handleSubmit(e: FormEvent) {
        e.preventDefault();
        setLoading(true);

        if (validateForm() && !loading) {
            void MakeForgotPasswordRequest(email);
        }
    }

    // Validate the input login form and give errors
    function validateForm(): boolean {
        if (email.length == 0) {
            setError("Please fill in an email address.");
            return false;
        } else if (!validateEmail(email)) {
            setError("Please fill in a valid email address.");
            return false;
        }

        return true;
    }

    return (
        <div className="login-box">
            <h1>Forgot Password</h1>
            <form
                className="login-form"
                action="index.html"
                method="post"
                onSubmit={handleSubmit}
            >
                <h2>E-mail</h2>
                <input
                    type="email"
                    name="forgot-password-email"
                    placeholder="Email Address"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setEmail(e.target.value)
                    }
                />
                <button className="general-button login-form-button">
                    Send Reset Password Request
                </button>
                {error ? (
                    <div className="glass-box declined contact-form-submission-text">
                        {error}
                    </div>
                ) : null}
                {loading ? (
                    <div className="glass-box loading contact-form-submission-text">
                        {"Sending request..."}
                    </div>
                ) : null}
                {message ? (
                    <div className="glass-box accepted contact-form-submission-text">
                        {message}
                    </div>
                ) : null}
            </form>
            <button
                onClick={() => setForgotPasswordPageActive(false)}
                className="create-account"
            >
                Back to the login screen.
            </button>
        </div>
    );

    function MakeForgotPasswordRequest(email: string) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email,
            }),
        };

        return fetch(
            `${Config.apiUrl}/auth/forgot_password`,
            requestOptions
        ).then((response) => {
            return handleResponse<ResponseForgotPasswordAPI>(response).then(
                (res: ResponseForgotPasswordAPI | null) => {
                    if (res == null) {
                        setError(
                            "You have sent too many requests, please wait for a while."
                        );
                    } else {
                        if (res.error && isMounted.current) {
                            setError(res.error);
                        } else if (res.message && isMounted.current) {
                            setMessage(res.message);
                        }
                    }
                    if (isMounted.current) {
                        setLoading(false);
                    }
                }
            );
        });
    }
}
