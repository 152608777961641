import React, { ReactElement, useState } from "react";

interface IProps {
    className?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    name: string;
    placeholder: string;
    onFocus?: () => void;
    onBlur?: () => void;
    style?: React.CSSProperties;
}
export default function HidePassword({
    className = "",
    name,
    onChange,
    placeholder,
    onFocus,
    onBlur,
    style,
}: IProps): ReactElement {
    const [passwordVisible, setPasswordVisible] = useState(false);
    return (
        <div className={`hide-password-box ${className}`}>
            <input
                type={passwordVisible ? "text" : "password"}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                style={style}
                onFocus={onFocus}
                onBlur={onBlur}
                required
            />{" "}
            <button
                type="button"
                className="hide-password-icon"
                onClick={() => {
                    setPasswordVisible(!passwordVisible);
                }}
            >
                <img
                    alt="toggle password text"
                    src={
                        passwordVisible
                            ? (require("../Images/Login/eye-outline.png") as string)
                            : (require("../Images/Login/eye-outline-crossed.png") as string)
                    }
                />
            </button>
        </div>
    );
}
