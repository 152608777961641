import React, { ReactElement } from "react";
import Dadjoke from "./dadJoke";
import ProgrammingCard from "./programmingCard";
import LanguageCard from "./languageCard";
import SoundCloudEmbed from "./SoundCloudEmbed";

const About = (): ReactElement => {
    function calculateAge() {
        //  My Birth date
        const birthDate: Date = new Date(2001, 4, 7);
        const diff_ms = Date.now() - birthDate.getTime();
        const age_dt = new Date(diff_ms);

        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    const LinearGradient = `linear-gradient(315deg, #ea8aba, #3f48cc)`;

    return (
        <div className="container container-about" id="About">
            <div className="about-information">
                <span
                    style={{
                        background: LinearGradient,
                    }}
                    className="colored-background"
                />
                <div className="content-card glass-box">
                    <h2>
                        Oh this is the about page... let me quickly tell
                        introduce myself.
                    </h2>
                    <p className="glass-box">
                        I&apos;m Merlijn Sevenhuijsen a {calculateAge()} year
                        old person who likes a broad variety of different
                        things. There is too little time and too many things to
                        do. Currently, I live in Stockholm, Sweden, as I am
                        seeking for new adventures. I grew up in a small town in
                        the Netherlands. Not much happens that much there, just
                        the occasional cow walking by. Recently, I have been
                        enjoying exploring nature on my bicycle. In addition to
                        that I love to make very bad puns.
                    </p>
                    <div className="hobbies-activities">
                        <div>
                            <h3>Hobbies:</h3>
                            <ul>
                                <li>Cycling</li>
                                <li>Exploring Nature</li>
                                <li>Programming</li>
                                <li>Creating Music</li>
                                <li>Playing Football</li>
                                <li>Playing videogames</li>
                                <li>Learning languages</li>
                            </ul>
                        </div>
                        <div>
                            <h3>Character Traits:</h3>
                            <ul>
                                <li>Curious</li>
                                <li>Adventurous</li>
                                <li>Persistent</li>
                                <li>Open minded</li>
                                <li>Used to working in teams</li>
                                <li>Creative</li>
                                <li>Enthusiastic</li>
                                <li>Compassionate</li>
                                {/* Add more character traits as needed */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-titles">
                <h1>Music and interests</h1>
            </div>

            <SoundCloudEmbed
                trackUrl="https://api.soundcloud.com/tracks/1516378543?secret_token=s-wARlCTkCt9b"
                username="Dussen"
                trackTitle="Never Be Alone"
                visual={true}
            />
            <SoundCloudEmbed
                trackUrl="https://api.soundcloud.com/tracks/1519222795?secret_token=s-z1b0ugXmSTl"
                username="Dussen"
                trackTitle="Unavailable"
                visual={true}
            />
            <SoundCloudEmbed
                trackUrl="https://api.soundcloud.com/tracks/1290554329"
                username="Dussen"
                trackTitle="Fluke"
                visual={true} // You can set the visual property as needed
            />
            <Dadjoke />
            <div className="about-titles">
                <h1>Languages</h1>
            </div>
            <section className="content-about-language glass-box">
                <LanguageCard
                    percentage={100}
                    language="Dutch"
                    color="#3f48cc" // A slightly darker shade for Dutch.
                />

                <LanguageCard
                    percentage={90}
                    language="English"
                    color="#3f48cc" // A moderate shade for English.
                />

                <LanguageCard
                    percentage={50}
                    language="German"
                    color="#3f48cc" // A slightly lighter shade for German.
                />

                <LanguageCard
                    percentage={20}
                    language="Swedish"
                    color="#3f48cc" // A lighter shade for Swedish.
                />

                <LanguageCard
                    percentage={20}
                    language="Italian"
                    color="#3f48cc" // A darker shade for Italian.
                />
            </section>
            <section className="content-about-programming">
                <ProgrammingCard
                    percentage={70}
                    programmingLanguage="Html"
                    color="rgb(255, 94, 0)" // This color works well with white text on a dark background.
                />
                <ProgrammingCard
                    percentage={60}
                    programmingLanguage="Css"
                    color="rgb(0, 38, 255)" // This color can provide good contrast with white text on a dark background.
                />
                <ProgrammingCard
                    percentage={90}
                    programmingLanguage="Javascript"
                    color="rgb(191, 163, 0)" // This yellow color can be effective with white text on a dark background.
                />
                <ProgrammingCard
                    percentage={85}
                    programmingLanguage="TypeScript"
                    color="rgb(0, 122, 204)" // A medium blue for TypeScript.
                />
                <ProgrammingCard
                    percentage={70}
                    programmingLanguage="Python"
                    color="rgb(0, 128, 79)" // This bright green can work well with white text on a dark background.
                />
                <ProgrammingCard
                    percentage={80}
                    programmingLanguage="Java"
                    color="rgb(0, 38, 255)" // This blue color can provide good contrast with white text on a dark background.
                />
                <ProgrammingCard
                    percentage={75}
                    programmingLanguage="C"
                    color="rgb(150, 150, 150)" // A neutral gray for C.
                />
                <ProgrammingCard
                    percentage={60}
                    programmingLanguage="C#"
                    color="rgb(140, 0, 255)" // This purple color can offer a distinct look on a dark background.
                />

                <ProgrammingCard
                    percentage={80}
                    programmingLanguage="C++"
                    color="rgb(255, 64, 64)" // A darker red for C++.
                />
            </section>
            <div className="experiences">
                <div className="experience-title">
                    <h1>Past Experiences</h1>
                </div>
                <div className="experiences-box glass-box">
                    <h2> 2023 - present: Master&rsquo;s degree </h2>
                    <p>
                        I am currently doing my second year of my double degree
                        (master) at KTH Royal Institute of Technology in
                        Stockholm, Sweden. Specialising in Autonomous Systems.
                    </p>
                </div>
                <div className="experiences-box glass-box">
                    <h2> 2022 - 2023: Master&rsquo;s degree </h2>
                    <p>
                        I did my first year of my double degree (Master) at
                        Università degli Studi di Trento in Trento, Italy.
                        Specialising in Autonomous Systems.
                    </p>
                </div>
                <div className="experiences-box glass-box">
                    <h2> 2019 - 2022: Bachelor Degree </h2>
                    <p>
                        I finished my bachelor degree in Computer Science and
                        Engineering at the Eindhoven University of Technology.
                    </p>
                </div>
                <div className="experiences-box glass-box">
                    <h2> 2012 - 2019: High School </h2>
                    <p>
                        I finished VWO on Dongemond College in Raamsdonksveer,
                        The netherlands.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default About;
