import React, { FunctionComponent, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../Reducer/interfaces";
import { AuthState } from "../Reducer/interfaces/auth/authInterfaces";
import LoggedInUserNavigation from "./loggedInUserNavigation";

import NavigationLink from "./navigationLink";
import UserNavigationList from "./userNavigationList";

interface IProps {
    setLoginVisibility: (value: boolean) => void;
    burgerActive: boolean;
    setBurgerActive: (value: boolean) => void;
}

const NavigationBar: FunctionComponent<IProps> = ({
    setLoginVisibility,
    burgerActive,
    setBurgerActive,
}) => {
    const [profileRoutes, setProfileRoutes] = useState(false);
    const auth: AuthState = useSelector((state: RootState) => state.auth);

    return (
        <nav className="glass-box navigation-bar">
            <Link
                to="/"
                className="navigation-logo fill-container white-text transparent-button"
            >
                Merlijn Sevenhuijsen
            </Link>
            <ul
                className={
                    burgerActive ? "navigation-ul nav-active" : "navigation-ul"
                }
            >
                <NavigationLink
                    burgerActive={burgerActive}
                    associatedPage=""
                    pageName="Home"
                    pageColor="#193225"
                />
                <NavigationLink
                    burgerActive={burgerActive}
                    associatedPage="About"
                    pageName="About"
                    pageColor="#3f48cc"
                />
                <NavigationLink
                    burgerActive={burgerActive}
                    associatedPage="Projects"
                    pageName="Projects"
                    pageColor="#ea8aba"
                />
                <NavigationLink
                    burgerActive={burgerActive}
                    associatedPage="Contact"
                    pageName="Contact"
                    pageColor="#e26f2e"
                />
                <LoggedInUserNavigation
                    profileRoutes={profileRoutes}
                    setLoginVisibility={setLoginVisibility}
                    setProfileRoutes={setProfileRoutes}
                />
            </ul>
            <button
                className={burgerActive ? "burger burgerToggle" : "burger"}
                onClick={() => {
                    setBurgerActive(!burgerActive);
                }}
            >
                <div className="line1"></div>
                <div className="line2"></div>
                <div className="line3"></div>
            </button>
            {profileRoutes && auth.loggedIn ? (
                <UserNavigationList disableMenu={disableProfileRoutes} />
            ) : null}
        </nav>
    );

    function disableProfileRoutes(node: Node) {
        const profileButtonClicked =
            node.parentNode.parentNode ==
            document.getElementById("login-button");
        if (!profileButtonClicked) {
            setProfileRoutes(false);
        }
    }
};

export default NavigationBar;
