import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../Reducer/interfaces";
import { AuthState } from "../Reducer/interfaces/auth/authInterfaces";

interface IProps {
    profileRoutes: boolean;
    setLoginVisibility: (value: boolean) => void;
    setProfileRoutes: (value: boolean) => void;
}

export default function LoggedInUserNavigation({
    profileRoutes,
    setProfileRoutes,
    setLoginVisibility,
}: IProps): ReactElement {
    const auth: AuthState = useSelector((state: RootState) => state.auth);

    return (
        <li className="navigation-container">
            {auth.loggedIn ? (
                <button
                    className="rgb-button transparent-button white-text"
                    style={{ height: "80%" }}
                    onClick={() => {
                        setProfileRoutes(!profileRoutes);
                    }}
                    id="login-button"
                >
                    <span>
                        {" "}
                        <img
                            className="fill-container"
                            src={
                                require("../Images/Login/user-profile.png") as string
                            }
                            alt="go to profile settings"
                        />
                    </span>
                </button>
            ) : (
                <button
                    className="rgb-button login-button transparent-button white-text"
                    onClick={() => {
                        setLoginVisibility(true);
                    }}
                >
                    <span>Log in</span>
                </button>
            )}
        </li>
    );
}
