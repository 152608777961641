import { WebsiteUser } from "./userInterface";
export default function authHeader(): HeadersInit {
    // return authorization header with jwt token
    const user: WebsiteUser = JSON.parse(
        localStorage.getItem("user")
    ) as WebsiteUser;

    const accessToken = localStorage.getItem("accessToken");

    if (user && accessToken) {
        return { Authorization: `Bearer ${accessToken}` };
    } else {
        return {};
    }
}

export function authHeaderRefresh(): HeadersInit {
    // return authorization header with jwt token
    const user: WebsiteUser = JSON.parse(
        localStorage.getItem("user")
    ) as WebsiteUser;

    const refreshToken = localStorage.getItem("refreshToken");

    if (user && refreshToken) {
        return { Authorization: `Bearer ${refreshToken}` };
    } else {
        return {};
    }
}

export function authHeaderForgotPassword(token: string): HeadersInit {
    // return authorization header with jwt token

    return { Authorization: `Bearer ${token}` };
}
