import React, {
    useState,
    useEffect,
    useRef,
    FormEvent,
    ReactElement,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateEmail } from "../../Pages/Contact/contact";
import { userAuthMessageActions } from "../../Reducer/actions/auth/authMessage";
import { userActions } from "../../Reducer/actions/auth/loginActions";
import { RootState } from "../../Reducer/interfaces";
import { AuthMessageState } from "../../Reducer/interfaces/auth/authInterfaces";
import HidePassword from "../hidePassword";

interface IProps {
    setRegisterPageActive: (value: boolean) => void;
    setForgotPasswordPageActive: (value: boolean) => void;
}

export default function LoginScreen({
    setRegisterPageActive,
    setForgotPasswordPageActive,
}: IProps): ReactElement {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    // Clear the current errors and messages
    userAuthMessageActions.clearMessageAndErrors();

    // Get the redux store of authentication states
    const authMessage: AuthMessageState = useSelector(
        (state: RootState) => state.authMessage
    );

    const mounted = useRef(true);
    const dispatch = useDispatch();

    // function for handling the submit button
    function handleSubmit(e: FormEvent) {
        e.preventDefault();

        setLoading(true);
        if (validateForm() && !loading) {
            dispatch(userActions.loginUser(email, password));
        }
        setLoading(false);
    }

    // Use effect to keep track of whether the ppage is mounted
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    // Validate the input login form and give errors
    function validateForm(): boolean {
        if (email.length == 0) {
            dispatch(
                userAuthMessageActions.setError(
                    "Please fill in an email address."
                )
            );
            return false;
        } else if (!validateEmail(email)) {
            dispatch(
                userAuthMessageActions.setError(
                    "Please fill in a valid email address."
                )
            );
            return false;
        } else if (password.length == 0) {
            dispatch(
                userAuthMessageActions.setError("Please fill in a password.")
            );
            return false;
        }

        return true;
    }

    return (
        <div className="login-box">
            <h1>Sign in</h1>
            <form
                className="login-form"
                action="index.html"
                method="post"
                onSubmit={handleSubmit}
            >
                <h2>E-mail</h2>
                <input
                    type="email"
                    name="login-email"
                    placeholder="Email Address"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setEmail(e.target.value)
                    }
                />
                <h2>Password</h2>
                <HidePassword
                    name="login-password"
                    placeholder="Password"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPassword(e.target.value)
                    }
                />
                <button className="general-button login-form-button">
                    Log In
                </button>
                {authMessage.error ? (
                    <div className="glass-box declined contact-form-submission-text">
                        {authMessage.error}
                    </div>
                ) : null}
                {loading ? (
                    <div className="glass-box loading contact-form-submission-text">
                        {"Logging in..."}
                    </div>
                ) : null}
                {authMessage.message ? (
                    <div className="glass-box accepted contact-form-submission-text">
                        {authMessage.message}
                    </div>
                ) : null}
            </form>
            <button
                onClick={() => setForgotPasswordPageActive(true)}
                className="forgot-password"
            >
                Forgot your password?
            </button>
            <button
                onClick={() => setRegisterPageActive(true)}
                className="create-account"
            >
                Not a member? Create your account here.
            </button>
        </div>
    );
}
