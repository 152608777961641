import React, { ReactElement } from "react";

interface IProps {
    trackUrl: string;
    username: string;
    trackTitle: string;
    visual?: boolean;
}

function SoundCloudEmbed({
    trackUrl,
    username,
    trackTitle,
    visual = true,
}: IProps): ReactElement {
    const soundCloudEmbedUrl = `https://w.soundcloud.com/player/?url=${encodeURIComponent(
        trackUrl
    )}&color=%23f4542c&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=${
        visual ? "true" : "false"
    }`;

    return (
        <div className="soundcloud-embed">
            <iframe
                width="100%"
                allow="autoplay"
                src={soundCloudEmbedUrl}
                title="SoundCloud Embed"
            />
            <div
                style={{
                    fontSize: "10px",
                    color: "#cccccc",
                    lineHeight: "10px",
                    wordBreak: "normal",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    fontFamily:
                        "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
                    fontWeight: 100,
                }}
            >
                <a
                    href={`https://soundcloud.com/${username}`}
                    title={username}
                    style={{
                        color: "#cccccc",
                        textDecoration: "none",
                    }}
                >
                    {username}
                </a>{" "}
                ·{" "}
                <a
                    href={trackUrl}
                    title={trackTitle}
                    style={{
                        color: "#cccccc",
                        textDecoration: "none",
                    }}
                >
                    {trackTitle}
                </a>
            </div>
        </div>
    );
}

export default SoundCloudEmbed;
