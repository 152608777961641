import React, { StrictMode } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";

import App from "./App/app";
import store from "./Reducer/store";

render(
    <StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </StrictMode>,

    document.getElementById("root")
);
